body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.options-pane {
  margin: 1rem;
}

.options-pane > * {
  margin: 0 1rem 0 1rem !important;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
}

.container {
  overflow-x: auto;
}
